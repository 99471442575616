.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

#jitsiMeeting-0{
  width: 100%;
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



.ant-layout{
  background: none !important;
}

.ant-layout-footer{
  background: none !important;
}

.ant-layout-header{
  background: none !important;
}

.ant-layout-content{
  background: none !important;
}


.ant-spin-dot-item{
  background-color: #000 !important;
}

.iniciarTransmision{
  width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 16px;
    background-color: #008002;
    margin-left: 10px;
    cursor: pointer;
    
}

.iniciarTransmision:hover{
  background-color: #0a630b;
}

#video-stream{
  width: 100%;
  height: 100%;
}

.terminarTransmision{
  width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 16px;
    background-color: #800000;
    margin-left: 10px;
    cursor: pointer;
    
}

.terminarTransmision:hover{
  background-color: #630a0a;
}

.contenedorVideos{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.contenedorVideos video{
  min-width: 0 !important; 
  min-height: 0 !important;
}

.inputChat input{
  background: #031e38 !important;
  border: #282c34;
  color: #fff; 
  height: 50px !important;
}

.inputChat .ant-input-group-addon{
  background: #031e38;
  border: none;
  color: #fff;
  cursor: pointer;
}

.ant-input:focus{
  outline: none !important;
  box-shadow: none !important
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
